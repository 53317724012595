var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"informe"}},[_c('IconoUsuario'),_c('div',{staticClass:"titulo"},[_vm._v(_vm._s(this.$t("informe.titulo")))]),_c('div',{staticClass:"cuerpo"},[_c('v-container',{staticStyle:{"width":"95%","max-width":"500px"},attrs:{"fluid":""}},[_c('div',{staticClass:"asterisco"},[_vm._v(" "+_vm._s(_vm.$t("informe.campoAsteriscos"))+" "),_c('lab',{staticStyle:{"color":"#ff5d55"}},[_vm._v(_vm._s(_vm.$t("informe.msgObligatorios")))])],1),_c('br'),_c('v-form',[_c('v-select',{attrs:{"clearable":"","label":_vm.$t('informe.labelEnfermedad'),"color":"#178649","items":_vm.patogenos,"item-value":"nombre_cientifico","item-text":"nombre_cientifico"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('i',[_vm._v(_vm._s(item.nombre_cientifico))]),_vm._v("   ("+_vm._s(item.nombre_vulgar)+") ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('i',[_vm._v(_vm._s(item.nombre_cientifico))]),_vm._v("   ("+_vm._s(item.nombre_vulgar)+") ")]}}]),model:{value:(_vm.informe.patogeno),callback:function ($$v) {_vm.$set(_vm.informe, "patogeno", $$v)},expression:"informe.patogeno"}},[_c('v-tooltip',{attrs:{"slot":"append-outer","top":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('router-link',{attrs:{"to":{ name: 'ayuda', query: { tab: '1' } }}},[_c('v-icon',_vm._g({attrs:{"size":"20","color":"#178649"}},on),[_vm._v(" mdi-help ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("informe.msgAyuda"))+" ")])])],1)],1),_c('upload-files'),_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('informe.labelFecha'),"color":"#178649","hint":"DD/MM/AA","readonly":""},model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDate),callback:function ($$v) {_vm.menuDate=$$v},expression:"menuDate"}},[_c('v-date-picker',{attrs:{"no-title":"","first-day-of-week":1,"locale":"es-es","color":"#178649"},on:{"input":function($event){_vm.menuDate = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-dialog',{attrs:{"width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"required":"","rules":_vm.reglaObligatorio,"value":_vm.location.address,"label":_vm.$t('informe.labelLocalizacion'),"color":"#178649","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-btn',{attrs:{"fab":"","dark":"","fixed":"","top":"","right":"","x-small":"","color":"#ff5d55"},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('div',{staticStyle:{"height":"400px"}},[_c('InformeMapa',{model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"dark":"","width":"100%","max-width":"500px","height":"50","color":"#ff5d55"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("informe.btnAceptar"))+" ")])],1)],1)],1),_c('v-select',{attrs:{"required":"","rules":_vm.reglaObligatorio,"label":_vm.$t('informe.labelArbolesDaniados'),"color":"#178649","suffix":_vm.$t('informe.suffixArboles'),"items":_vm.extension},model:{value:(_vm.informe.extension_arboles),callback:function ($$v) {_vm.$set(_vm.informe, "extension_arboles", $$v)},expression:"informe.extension_arboles"}},[_c('v-tooltip',{attrs:{"slot":"append-outer","top":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"20","color":"#178649"}},on),[_vm._v(" mdi-help ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("informe.ayuda1")))])])],1),_c('v-text-field',{attrs:{"required":"","label":_vm.$t('informe.labelPorcentajeAfectados'),"color":"#178649","suffix":"%","rules":_vm.reglasExtension},model:{value:(_vm.informe.extension_pies),callback:function ($$v) {_vm.$set(_vm.informe, "extension_pies", $$v)},expression:"informe.extension_pies"}},[_c('v-tooltip',{attrs:{"slot":"append-outer","top":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"20","color":"#178649"}},on),[_vm._v(" mdi-help ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("informe.ayuda2")))])])],1),_c('v-select',{attrs:{"label":_vm.$t('informe.labelSeveridad'),"color":"#178649","suffix":"%","items":_vm.severidadOpciones},model:{value:(_vm.informe.severidad),callback:function ($$v) {_vm.$set(_vm.informe, "severidad", $$v)},expression:"informe.severidad"}},[_c('v-tooltip',{attrs:{"slot":"append-outer","top":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"20","color":"#178649"}},on),[_vm._v(" mdi-help ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("informe.ayuda3")))])])],1),_c('v-textarea',{attrs:{"color":"#178649","auto-grow":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("informe.labelObservaciones"))+" ")]},proxy:true}]),model:{value:(_vm.informe.observaciones),callback:function ($$v) {_vm.$set(_vm.informe, "observaciones", $$v)},expression:"informe.observaciones"}},[_c('v-tooltip',{attrs:{"slot":"append-outer","top":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"20","color":"#178649"}},on),[_vm._v(" mdi-help ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("informe.ayuda4")))])])],1),_c('v-text-field',{attrs:{"required":"","rules":_vm.reglaObligatorio,"label":_vm.$t('informe.labelCorreoTelefono'),"color":"#178649"},model:{value:(_vm.informe.contacto),callback:function ($$v) {_vm.$set(_vm.informe, "contacto", $$v)},expression:"informe.contacto"}}),_c('v-switch',{attrs:{"label":((_vm.$t('informe.msgPublico')) + " " + (_vm.informe.isPublic ? _vm.$t('informe.publico') : _vm.$t('informe.privado'))),"color":"#178649"},model:{value:(_vm.informe.isPublic),callback:function ($$v) {_vm.$set(_vm.informe, "isPublic", $$v)},expression:"informe.isPublic"}}),_c('v-divider'),_c('v-btn',{staticClass:"white--text",attrs:{"width":"100%","height":"50","color":"#ff5d55","disabled":!_vm.valid},on:{"click":_vm.validar}},[_vm._v(" "+_vm._s(_vm.$t("informe.botonEnviar"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }